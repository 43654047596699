:root {
  --font-famiry: "M PLUS Rounded 1c", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  --primary-color: #1e5635;
  --primary-color-rgb-text: 30, 86, 53;
  --secondary-color: #dcb487;
  --secondary-color-rgb-text: 220, 180, 135;
  --background-primary-color: #e2eef2;
  --background-primary-color-rgb-text: 226, 238, 242;
  --background-secondary-color: #f7f3f0;
  --background-secondary-color-rgb-text: 247, 243, 240;
}
